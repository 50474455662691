import Observable from 'zen-observable';
import debounce from 'lodash/debounce';
export default new Observable(observer => {
  let target;
  let last = 0;
  const notifySubscribers = debounce(() => {
    const scrollTo = // @ts-ignore
    target.scrollTop || window.pageYOffset || document.documentElement.scrollTop;
    observer.next({
      scrollTo,
      lastScrollTo: last
    });
    last = scrollTo;
  }, 60, {});

  if (typeof document !== 'undefined') {
    target = document.querySelector('[role=main]') || window;
    target.addEventListener('scroll', notifySubscribers);
  }
});