import envConfig from '@/config/environment'; // TODO throw errors when price id
// environment variables are unset

const MAX_FREE_WIDGETS = 3;
const MAX_STARTER_WIDGETS = 20;
export const FREE_SEAT = {
  nickname: 'free',
  price: 0,
  permissions: {
    canCopyWidgetSnippet: true
  }
}; // export const HOBBY_PLAN = {
//   nickname: 'hobby',
//   price: 29,
//   priceId: 'price_1GyDrLAORRjdBXUFYFF7kegx',
//   description:
//     'Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard.',
//   title: 'Hobby Plan',
//   usps: [
//     'Fingerstache disrupt',
//     'Franzen hashtag',
//     'Tilde art party',
//     'Banh mi cornhole',
//   ],
// };

export const STARTER_SEAT = {
  nickname: 'starter',
  price: 19.99,
  priceId: process.env.NEXT_PUBLIC_STRIPE_STARTER_PRICE_ID || '',
  description: `Enable publishing up to ${MAX_STARTER_WIDGETS} widgets & premium level features.  Billed monthly.`,
  title: 'Starter Plan',
  usps: [`${MAX_STARTER_WIDGETS} embeddable Embeddable Widgets`, 'Premium-Teir features', 'Template Library', 'Excel Library Access', '1 on 1 Widget Setup', 'Live Chat Support']
};
export const BUSINESS_SEAT = {
  nickname: 'basic',
  price: 24.99,
  priceId: process.env.NEXT_PUBLIC_STRIPE_BUSINESS_PRICE_ID || '',
  description: 'Enable publishing 20 embeddable widgets.  Billed monthly',
  title: 'Basic Plan',
  usps: ['20 Embeddable Widgets', 'Template Library', 'Excel Library Access', '1 on 1 Widget Setup', 'Live Chat Support']
};
export const products = [STARTER_SEAT, BUSINESS_SEAT];
export const advertising = {
  active: envConfig.isProduction && false
};
export default {
  products,
  trialPeriodDays: 14,
  advertising,
  maximumFreeWidgets: MAX_FREE_WIDGETS
};