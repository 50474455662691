import features from '@/config/features'; // import TacticIcon from '../../../../public/icons/navigation/tactic.svg';
// import GoalIcon from '../../../../public/icons/navigation/goal.svg';
// import SearchIcon from '../../../../public/icons/navigation/search.svg';
// import MoneyBagIcon from '../../../../public/icons/navigation/money-bag.svg';
// import BillIcon from '../../../../public/icons/navigation/bill.svg';

export const primaryMenus = [// {
//   text: 'About',
//   href: '/about/',
//   subLinks: [],
// },
{
  text: 'Calculators',
  href: '/calculators',
  subLinks: [// {
    //   text: 'Self Employment',
    //   Icon: TacticIcon,
    //   description: 'Business tax strategies that help you explore new ideas',
    //   href: '/self-employment/',
    // }
  ]
}, features.chartsNavigation ? {
  text: 'Charts',
  href: '/charts',
  subLinks: [// {
    //   text: 'Self Employment',
    //   Icon: TacticIcon,
    //   description: 'Business tax strategies that help you explore new ideas',
    //   href: '/self-employment/',
    // }
  ]
} : null].filter(Boolean);